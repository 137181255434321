import React from "react";
import Layout from "../components/common/Layout";
import PageHeader from "../components/common/PageHeader";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import ContactUs from "../components/common/ContactUs";
import { getImage } from "gatsby-plugin-image";
import Seo from "../components/default/seo";

export default function OurMission({ data }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <Layout>
      <Seo
        title={t("missionHeaderTitle")}
        description={t("missionHeaderText")}
        lang={language}
      />
      <PageHeader
        title={t("missionHeaderTitle")}
        text={t("missionHeaderText")}
        img={getImage(data.hero)}
      />
      <section>
        <div className="mx-auto max-w-3xl space-y-8 py-12 text-center md:py-32">
          <h2 className="text-4xl md:text-5xl">{t("missionIntroTitle")}</h2>
          <p className="px-8">{t("missionIntroText")}</p>
        </div>
      </section>
      <ContactUs />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { in: ["common", "mission"] } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    hero: file(relativePath: { regex: "/our-mission/hero.jpg/" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
